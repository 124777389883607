import { AppStoreButton, ButtonsContainer } from "react-mobile-app-button";
import { ReactComponent as Logo } from '../logo.svg';
import '../styles/Home.css';

const IOSUrl = "https://apps.apple.com/ch/app/casazurigo/id6502548868";

const Home = () => {
    return (
        <div className="Home">
            <h1>CasaZurigo</h1>

            <Logo />

            <ButtonsContainer direction="column">
                <AppStoreButton
                    url={IOSUrl}
                    theme={"dark"}
                    className={"custom-style"}
                />
            </ButtonsContainer>
        </div>
    );
}

export default Home;