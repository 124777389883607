import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Privacy from "./pages/Privacy";
import TermsOfUse from "./pages/TermsOfUse";
import Home from "./pages/Home";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Home />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="terms-of-use" element={<TermsOfUse />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;